import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { HorizontalBar } from 'react-chartjs-2'

const BillPageTemplate = ({ title, notice, content, contentComponent, asideImages, rateSchedule }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
            <p>For more information regarding the NFBWA Fee <Link to="/blog/2020-12-07-what-is-the-nfbwa-fee/">click here</Link>.</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  function AsideImages({asideImages}) {
    if( !Array.isArray(asideImages) || !asideImages.length) {
      return (
        < ></ >
      )
    }

    return (
      <div className="column is-3 is-offset-1 vertical-justify" style={{marginTop: '6rem'}}>
        {asideImages.map(image => (
          < >
            <Image className="asideImage" fixed={image.image.childImageSharp.fixed} alt={image.image.alt} />
          </ >
        ))}
      </div>
    )
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light" style={{ marginLeft: '-1.5rem'}}> {title} </h1>
              <hr/>
              <div className="content">
                <h3>Online Billpay</h3>

                <p>To register your account online or to pay your bill, <OutboundLink href="https://mds.firstbilling.com/">click here</OutboundLink></p>

                <h3>Customer Support</h3>

                <p>For billing, service requests and other inquiries use the following:</p>

                <p><strong>General Customer Service Number:</strong> (281) 290-6500</p>

                <p><strong>Customer/Billing Inquiries Email:</strong> <OutboundLink href="mailto:general@municipalops.com">info@mdswater.com</OutboundLink></p>
              </div>
              <PageContent className="content" content={content} />
            </div>
            <div className="section">
              <h3 className="title is-size-3" >Rate Schedule</h3>
              <p>Below shows the cumulative water rate schedule.</p>
              {rateSchedule.map( entry => (
                <>
                  <h3 className="title is-size-5">{entry.label}</h3>
                  <table className="table">
                  <tbody>
                  {entry.period.map( entry => (
                    <tr>
                      <td>$&nbsp;{entry.rate.toFixed(2)}</td>
                      <td>{entry.description}</td>
                    </tr>
                  ))}
                  </tbody>
                  </table>
                </>
              ))}
              <Notice notice={notice} />
            </div>
            <div className="section">
              <div className="content">
                <h4>Notice About Confidentiality of Customer Information</h4>
                <p>Chapter 182 of the Texas Utilities Code as amended in 2021 by House Bill 872 provides that a government-operated utility may not disclose personal information (customer's address, telephone number, and social security number) in a customer's account, or any information related to the volume or units of utility usage or amounts billed or collected for such utility usage, unless the customer elects to allow such information disclosed.</p>

                <p>The Utilities Code requires the District to provide notice of the customer's right to allow disclosure of his or her information. Therefore, if you wish to allow disclosure of your personal information, please contact the District Operator, Municipal District Services, L.L.C, via telephone at 281-290-6500 or via email at info@mdswater.com so they can provide you a disclosure form for you to complete and return.</p>

                <p>NOTE: The District is allowed to disclose information in a customer's account record to federal, state, or local government officials; to District employees, officials and operations personnel; to consumer reporting agencies; to a contractor or subcontractor approved by and providing services to the District, the state, a political subdivision of the state, or the United States; or to any other provider of utility services.</p>
              </div>
            </div>
          </div>
          <AsideImages asideImages={asideImages} />
        </div>
      </div>
    </section>
  )
}

BillPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BillPageTemplate
